import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type SEOProps = {
  description?: string
  lang?: string
  meta?: any
  keywords?: any
  title: string
  imageUrl: any
}

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
  imageUrl,
}) => {
  const { site } = useStaticQuery(
	graphql`
	  query {
		site {
		  siteMetadata {
			title
			description
			author
			imageUrl
		  }
		}
	  }
	`
  )

  const metaDescription = description || site.siteMetadata.description

  return (
	<Helmet
	  htmlAttributes={{
		lang,
	  }}
	  title={title}
	  titleTemplate={`${site.siteMetadata.title} | %s`}
	  meta={[
		{
		  name: `description`,
		  content: metaDescription,
		},
		{
		  property: `og:title`,
		  content: title,
		},
		{
		  property: `og:image`,
		  content: imageUrl,
		},
		{
		  property: `og:description`,
		  content: metaDescription,
		},
		{
		  property: `og:type`,
		  content: `website`,
		},
		{
		  name: `twitter:card`,
		  content: `summary`,
		},
		{
		  name: `twitter:creator`,
		  content: site.siteMetadata.author,
		},
		{
		  name: `twitter:title`,
		  content: title,
		},
		{
		  name: `twitter:image`,
		  content: imageUrl,
		},
		{
		  name: `twitter:description`,
		  content: description,
		},
	  ]
		.concat(
		  keywords.length > 0
			? {
				name: `keywords`,
				content: keywords.join(`, `),
			  }
			: []
		)
		.concat(meta)}
	/>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

export default SEO
